import React, { useState } from 'react';
import { Link } from 'gatsby';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion';
import { AnimatePresence, motion } from 'framer-motion';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { mainNavigation } from '../data/site-navigation';

function MobileMenu({ isMobileMenuOpen, setMobileMenuOpen }) {
  const handleClose = () => {
    setMobileMenuOpen(false);
  };

  const MotionDialogOverlay = motion.custom(DialogOverlay);
  const MotionDialogContent = motion.custom(DialogContent);

  return (
    <AnimatePresence>
      <MotionDialogOverlay
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={isMobileMenuOpen}
        isOpen={isMobileMenuOpen}
        onDismiss={handleClose}
        className="fixed inset-0 z-40 bg-gray-900 bg-opacity-75"
      >
        <MotionDialogContent
          initial={{ x: '100%' }}
          animate={{ x: '0%' }}
          exit={{ x: '100%' }}
          transition={{ min: 0, max: 100, bounceDamping: 9 }}
          aria-label="Sidebar menu"
          className="relative flex flex-col h-full max-w-sm px-4 pt-6 pb-8 my-0 ml-auto overflow-y-auto bg-brand-black focus:outline-none focus:shadow-outline"
        >
          <button
            type="button"
            onClick={handleClose}
            aria-label="Close sidebar"
            className="flex items-center self-end justify-center w-10 h-10 mb-8 focus:shadow-none"
          >
            <svg
              aria-hidden
              focusable={false}
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
              className="w-10 h-10 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <ul className="px-4 space-y-2 text-right">
            {mainNavigation.map((navItem) =>
              navItem.submenu ? (
                <li key={nanoid()}>
                  <SubMenu navItem={navItem} handleClose={handleClose} />
                </li>
              ) : (
                <li key={nanoid()}>
                  <Link
                    to={navItem.slug}
                    className="block py-2 pr-6 text-lg font-bold tracking-wide text-white uppercase transition duration-150 ease-in-out focus:bg-white focus:bg-opacity-25 focus:shadow-none"
                  >
                    {navItem.label}
                  </Link>
                </li>
              )
            )}
          </ul>
        </MotionDialogContent>
      </MotionDialogOverlay>
    </AnimatePresence>
  );
}

MobileMenu.propTypes = {
  isMobileMenuOpen: PropTypes.bool,
  setMobileMenuOpen: PropTypes.func,
};

function SubMenu({ navItem, handleClose }) {
  const { label, submenu } = navItem;

  const [isExpanded, setExpanded] = useState(false);

  return (
    <Accordion collapsible onChange={() => setExpanded((prev) => !prev)}>
      <AccordionItem>
        <h3 className="relative text-right">
          <AccordionButton
            className={`flex items-center justify-end w-full py-2 pr-6 text-lg font-bold tracking-wide uppercase transition duration-150 ease-in-out focus:bg-white focus:bg-opacity-25 focus:shadow-none${
              isExpanded ? ' text-brand-yellow' : ' text-white'
            }`}
          >
            {label}
            <AnimatePresence>
              <motion.span
                aria-hidden
                focusable="false"
                className="absolute inset-y-0 right-0 flex items-center justify-center"
                initial={{ rotate: 0 }}
                animate={{ rotate: isExpanded ? 90 : 0 }}
                exit={{ rotate: 0 }}
                transition={{ duration: 0.3 }}
              >
                <svg
                  aria-hidden
                  focusable={false}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  className="w-5 h-5 text-white"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </motion.span>
            </AnimatePresence>
          </AccordionButton>
        </h3>

        <AccordionPanel>
          <AnimatePresence>
            {isExpanded && (
              <motion.nav
                key={nanoid()}
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { height: 'auto', opacity: 1, overflow: 'visible' },
                  collapsed: { height: 0, opacity: 0, overflow: 'hidden' },
                }}
                transition={{ min: 0, max: 100, bounceDamping: 9 }}
                className="overflow-y-hidden"
              >
                <ul>
                  {submenu.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={item.slug}
                        className="inline-block w-full px-6 py-2 text-white"
                        onClick={handleClose}
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </motion.nav>
            )}
          </AnimatePresence>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

SubMenu.propTypes = {
  navItem: PropTypes.object,
  handleClose: PropTypes.func,
};
export { MobileMenu };
