import { FacebookIcon, InstagramIcon } from '../components/vectors';

const mainNavigation = [
  {
    label: 'Services',
    submenu: [
      {
        label: 'All Services',
        slug: '/services/',
      },
      {
        label: 'Level 2',
        slug: '/services/level-2-services/',
      },
      {
        label: 'Domestic',
        slug: '/services/domestic-services/',
      },
      {
        label: 'Commercial',
        slug: '/services/commercial-services/',
      },
    ],
  },
  {
    label: 'Our Story',
    slug: '/our-story/',
  },
  {
    label: 'Contact',
    slug: '/contact/',
  },
];

const socialLinks = [
  {
    label: 'Facebook',
    url: 'https://www.facebook.com/pacificblueelectrical/',
    icon: FacebookIcon,
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/pacificblueelectrical/',
    icon: InstagramIcon,
  },
];

export { mainNavigation, socialLinks };
