import React from 'react';
import { nanoid } from 'nanoid';

import { CommercialIcon, DomesticIcon, Level2Icon } from './vectors';
import { ListItem } from './list-item';
import { BlueLink } from './blue-link';

const services = [
  // {
  //   id: nanoid(),
  //   label: 'Domestic Services',
  //   slug: '/services/domestic-services/',
  //   icon: DomesticIcon,
  //   skills: [
  //     'Highly Trained',
  //     'Quality Workmanship',
  //     'Clean & Tidy',
  //     'Great Communications',
  //   ],
  // },
  // {
  //   id: nanoid(),
  //   label: 'Commercial Services',
  //   slug: '/services/commercial-services/',
  //   icon: CommercialIcon,
  //   skills: [
  //     'All Power & Lighting',
  //     'Fault Finding & Repairs',
  //     'Emergency Light Maintenance',
  //   ],
  // },
  {
    id: nanoid(),
    label: 'Our Services Include',
    slug: '/services/level-2-services/',
    icon: Level2Icon,
    skills: [
      'Cross Arm Replacements',
      'Overhead Service Mains',
      'Underground Service Mains',
      'Private Pole Installations and Replacements',
      'Defect Rectifications',
      'Meter Protection Device Installations',
      'Smart Meter Installations',
      'Point Of Attachment Bracket Repairs',
      'Switchboard Relocations and Upgrades',
      'Power Separation for Multi-Occupancy Dwellings',
      'Upgrades from Single Phase to Three Phase',
    ],
  },
];

function ServicesGrid() {
  return (
    <article className="w-full px-4 pb-24 mx-auto sm:px-8 lg:px-20 bg-brand-black">
      <div className="grid gap-6 lg:grid-cols-1">
        {services.map((service) => (
          <div
            className="flex flex-col w-full max-w-2xl px-4 py-8 mx-auto border-4 border-white rounded-lg sm:flex-row lg:flex-col"
            key={service.id}
          >
            <div className="mx-auto h-52">
              <service.icon className="h-full mx-auto" />
            </div>
            <div className="flex-1 md:flex md:flex-col md:justify-between">
              <div className="flex flex-col items-center px-4 py-4 mx-auto md:px-6">
                <div>
                  <h2 className="mb-4 text-xl font-extrabold leading-none text-left text-white text-bold">
                    {service.label}:
                  </h2>
                  <ul className="space-y-2">
                    {service.skills.map((skill, i) => (
                      <ListItem key={i}>{skill}</ListItem>
                    ))}
                  </ul>
                </div>
              </div>
              {/* <div className="flex items-end justify-center">
                <BlueLink
                  to={service.slug}
                  text="Find out more here"
                  ariaLabel={`Find out more about ${service.label}`}
                />
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </article>
  );
}

export { ServicesGrid };
