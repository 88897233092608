import React, { useState } from 'react';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, MenuButton, MenuList } from '@reach/menu-button';

import { mainNavigation } from '../data/site-navigation';
import { LogoMobile, Logo, ThreeBestRatedLogo } from './vectors';
import { MobileMenu } from './mobile-menu';
import { useGraphQL } from '../hooks';

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const {
    site: { siteMetadata },
  } = useGraphQL();
  return (
    <header className="sticky top-0 z-20 shadow bg-brand-black">
      <MobileMenu
        isMobileMenuOpen={isMobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
      <nav>
        <div className="px-4 py-6 mx-auto max-w-7xl sm:px-8 lg:px-20">
          <div className="flex items-center justify-between">
            <div className="flex space-x-8">
              <Link to="/" className="flex items-center flex-shrink-0">
                <LogoMobile className="w-auto h-14 sm:hidden" />
                <Logo className="hidden w-auto sm:block sm:h-14 lg:h-20" />
              </Link>
              {/* <a href="https://threebestrated.com.au/electricians-in-port-macquarie-nsw">
                <ThreeBestRatedLogo className="flex-shrink-0 block h-14 lg:h-20" />
              </a> */}
            </div>
            {/* <div className="flex space-x-4 md:space-x-8">
              <ul className="hidden space-x-8 md:ml-6 md:flex">
                {mainNavigation.map((navItem) =>
                  navItem.submenu ? (
                    <li key={nanoid()} className="flex">
                      <Menu>
                        {({ isExpanded }) => (
                          <SubMenu navItem={navItem} isExpanded={isExpanded} />
                        )}
                      </Menu>
                    </li>
                  ) : (
                    <li key={nanoid()} className="flex">
                      <Link
                        to={navItem.slug}
                        partiallyActive={navItem.slug !== '/'}
                        className="inline-flex items-center px-1 tracking-wide text-white transition duration-150 ease-in-out"
                      >
                        {navItem.label}
                      </Link>
                    </li>
                  )
                )}
              </ul>
              <a
                href={`tel:${siteMetadata.phone.split(' ').join('')}`}
                className="self-center block px-2 py-1 font-serif text-xl font-medium leading-none tracking-wide whitespace-no-wrap bg-white rounded hover:bg-brand-yellow"
              >
                Call us {siteMetadata.phone}
              </a>

              <button
                type="button"
                className="flex items-center ml-4 space-x-1 md:hidden focus:outline-none focus:shadow-outline"
                onClick={() => setMobileMenuOpen(true)}
              >
                <svg
                  aria-hidden
                  focusable={false}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  className="w-6 text-white"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div> */}
          </div>
        </div>
      </nav>
    </header>
  );
}

function SubMenu({ navItem, isExpanded = false }) {
  return (
    <>
      <MenuButton className="inline-flex items-center px-1 space-x-2 tracking-wide text-white transition duration-150 ease-in-out hover:underline focus:underline">
        {navItem.label}{' '}
        <motion.span
          aria-hidden
          focusable="false"
          initial={{ rotate: 0 }}
          animate={{ rotate: isExpanded ? 90 : 0 }}
          exit={{ rotate: 0 }}
          className="flex items-center justify-center"
        >
          <svg
            aria-hidden
            focusable={false}
            fill="currentColor"
            viewBox="0 0 20 20"
            className="w-4 h-4 text-white"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </motion.span>
      </MenuButton>
      <MenuList className="relative z-20 mt-4 transform outline-none -translate-x-1/4">
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              variants={{
                open: { opacity: 1, y: 0 },
                closed: { opacity: 0, y: '-0.25rem' },
              }}
              initial="closed"
              animate="open"
              exit="closed"
              className="text-white bg-black border border-white rounded"
            >
              <nav className="py-3">
                <ul>
                  {navItem.submenu.map((submenu) => (
                    <li key={submenu.id} className="flex w-full">
                      <Link
                        to={submenu.slug}
                        className="block px-6 py-2 text-white hover:underline focus:underline"
                      >
                        {submenu.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </motion.div>
          )}
        </AnimatePresence>
      </MenuList>
    </>
  );
}

SubMenu.propTypes = {
  isExpanded: PropTypes.bool,
  navItem: PropTypes.object,
};

export { Header };
